<template>
  <div
    class="flex flex-col"
    :class="containerClassname"
  >
    <span
      v-if="label"
      class="text-space-dark-blue px-1 pb-1"
      :class="labelClassname"
    >
      {{ label }}
    </span>
    <textarea
      ref="textarea"
      :value="inputVal"
      @input="onInputChanged"
      class="wrapper w-full relative py-4 px-5 rounded-xl border-none bg-space-dark-blue text-white flex resize-none outline-none"
      :class="textareaClassname"
      :placeholder="placeholder"
    />
    <span
      v-if="errors && field && errors[field] && errors[field] !== ''"
      class="text-space-darker-red px-1 pt-1"
    >
      {{ errors[field] }}
    </span>
  </div>
</template>

<script>
export default {
  name: "TextAreaFormInput",
  props: {
    label : String,
    placeholder : String,
    value: {
      default : ''
    },
    field: String,
    errors: Object,
    textareaClassname: String,
    labelClassname: String,
    containerClassname: String,
    maxLines: Number
  },
  mounted() {
    if (this.maxLines) {
      this.$refs.textarea.addEventListener('keydown', this.handleKeyDown);
    }
  },
  beforeDestroy() {
    if (this.maxLines){
      this.$refs.textarea.removeEventListener('keydown', this.handleKeyDown);
    }
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        // resize the textarea
        this.$refs.textarea.style.height = '1px';
        this.$refs.textarea.style.height = 25 + this.$refs.textarea.scrollHeight + 'px';
      },
    },
  },
  methods: {
    handleKeyDown(e) {
      // if key is enter and actuel length is same as max lines or any other key and length is gonna be greater than max lines then prevent default
      if ((e.key === 'Enter' && this.$refs.textarea.value.split('\n').length === this.maxLines) || (e.key !== 'Enter' && this.$refs.textarea.value.split('\n').length > this.maxLines)) {
        e.preventDefault();
      }
    },
    onInputChanged({target}) {
      this.$emit('input', target.value);
    }
  }
};
</script>

<style scoped>

</style>
