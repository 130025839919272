<template>
  <div class="flex flex-col relative" v-click-outside="hideResult">
    <span
      v-if="label"
      class="text-space-dark-blue px-1 pb-1"
    >
      {{ label }}
    </span>
    <button
      @click="open = !open"
      class="wrapper py-4 px-5 outline-none w-full flex items-center justify-between rounded-full"
      :class="{'bg-space-dark-blue text-white' : !open, 'bg-white text-space-dark-blue shadow-lg font-bold': open, 'text-opacity-60': !hasSelected}"
    >
      <span class="pl-2 block truncate">
        {{ selectedElement }}
      </span>
      <span class="inset-y-0 right-0 flex items-center pointer-events-none">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path v-if="!open" stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
          <path v-else stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
        </svg>
      </span>
    </button>
    <span
      v-if="errors && field && errors[field] && errors[field] !== ''"
      class="text-space-darker-red px-1 pt-1"
    >
      {{ errors[field] }}
    </span>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ul v-if="open" class="absolute left-0  top-10 border max-h-56 overflow-y-auto border-gray-50 z-10 mt-2 w-full bg-white shadow-lg rounded-3xl" tabindex="-1" role="listbox">
        <li v-for="item in items" :key="item.id" @click="selectItem(item)" class="cursor-pointer py-4 pl-5 w-full text-space-dark-blue hover:bg-gray-100 hover:font-bold">
          {{item.value}}
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>

import ClickOutside from 'vue-click-outside';

export default {
  name: "SelectFormInput",
  directives: {
    ClickOutside
  },
  props : {
    value: String,
    items : {
      type : Array,
      default : () => {}
    },
    label : String,
    placeholder: String,
    field: String,
    errors: Object,
  },
  data() {
    return {
      open: false,
      selectedElement: this.value ? this.value : this.placeholder
    };
  },
  computed: {
    hasSelected() {
      return this.selectedElement !== this.placeholder;
    }
  },
  watch: {
    value: function (value) {
      this.selectedElement = value;
    }
  },
  methods: {
    selectItem(value) {
      this.open = false;
      this.selectedElement = value.value;
      this.$emit('input', value.value);
    },
    hideResult() {
      this.open = false;
    }
  }
};
</script>

<style scoped>
.wrapper:not(.shadow-none){
  box-shadow: 0 0 20px rgba(77, 42, 156, 0.43);
}
</style>
